// report schema version 1

import { SortDirection } from '@/helpers/data-query';
import { DefaultResponse } from './default-response';

export interface ReportQueryItemDTO {
  metadata: {
    reporter: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
      organizations_id: number;
      custom_id: string;
      phone_number: string;
    };
    reportActive: boolean;
    time_created: string;
    org_id: number;
    location?: {
      lat: number;
      lon: number;
    };
  };
}

export interface FullReport extends ReportQueryItemDTO {
  integrations: {
    [key: string]: {
      normalized: Record<string, any>;
      raw: Record<string, any>;
    };
  };
  shared: Record<string, any>;
}

// report schema version 2

export enum TagTypeEnum {
  tagTypeString = 'string',
  tagTypeNumber = 'number',
  tagTypeBoolean = 'boolean',
}

export interface RBTag {
  _id: string;
  key: string;
  value: string | number | boolean;
}

export interface RBTagIdentifier {
  key: string;
  type: TagTypeEnum;
}

// TODO: use class transformer on this
export interface ReportMetadataDTO {
  _id: string;
  title: string;
  tags: RBTag[];
  report_active: boolean;
  date_created: string;
  org: number;
  integration: string;
  ui: string;
  exporter: string;
}

export enum TagQueryCondition {
  equal_to = 'equal_to',
  not_equal_to = 'not_equal_to',
  greater_than = 'greater_than',
  greater_than_or_equal_to = 'greater_than_or_equal_to',
  less_than = 'less_than',
  less_than_or_equal_to = 'less_than_or_equal_to',
  range = 'range',
}

export function getConditionName(tagCondition: TagQueryCondition) {
  switch (tagCondition) {
    case TagQueryCondition.equal_to:
      return 'equal to';
    case TagQueryCondition.not_equal_to:
      return 'not equal to';
    case TagQueryCondition.greater_than:
      return 'greater than';
    case TagQueryCondition.greater_than_or_equal_to:
      return 'greater than or equal to';
    case TagQueryCondition.less_than:
      return 'less than';
    case TagQueryCondition.less_than_or_equal_to:
      return 'less than or equal to';
    case TagQueryCondition.range:
      return 'in range';
    default:
      return '(bad condition)';
  }
}

export function getPossibleConditions(tagType: TagTypeEnum) {
  switch (tagType) {
    case TagTypeEnum.tagTypeString:
      return [TagQueryCondition.equal_to, TagQueryCondition.not_equal_to];
    case TagTypeEnum.tagTypeNumber:
      return [
        TagQueryCondition.equal_to,
        TagQueryCondition.not_equal_to,
        TagQueryCondition.greater_than,
        TagQueryCondition.greater_than_or_equal_to,
        TagQueryCondition.less_than,
        TagQueryCondition.less_than_or_equal_to,
        TagQueryCondition.range,
      ];
    case TagTypeEnum.tagTypeBoolean:
      return [TagQueryCondition.equal_to, TagQueryCondition.not_equal_to];
    default:
      return [TagQueryCondition.equal_to, TagQueryCondition.not_equal_to];
  }
}

export interface RBTagQueryDTO {
  key: string;
  condition: TagQueryCondition;
  value: string | number | boolean;
}

export enum TagMatch {
  all = 'all',
  some = 'some',
}

export interface ReportQueryDTO {
  org_ids?: number[];
  tags?: RBTagQueryDTO[];
  tagMatch?: TagMatch;
  active?: boolean;
  search_str?: string;
  sort_by?: keyof ReportMetadataDTO;
  sort_dir?: SortDirection;
  integration?: string;
  limit?: number;
  offset?: number;
}

export function getTag(tags: RBTag[], key: string) {
  return tags.find(tag => tag.key === key)?.value;
}

export enum RecordTypes {
  BIN = 'bin',
  JSON = 'json',
  FILE = 'file',
}

export interface RBRecordDTO {
  _id: string;
  timestamp: string;
  tags: RBTag[];
  report: string;
  integration: string;
  type: RecordTypes;
  data: any;
}
export interface SearchReportMetadataReponse extends DefaultResponse {
  reports: ReportMetadataDTO[];
  total: number;
}

export interface GetReportResponse extends DefaultResponse {
  report: ReportMetadataDTO;
  records: RBRecordDTO[];
}

export interface CloseReportResponse extends DefaultResponse {
  report: ReportMetadataDTO;
  records: RBRecordDTO[];
}

export interface GetTagsResponse extends DefaultResponse {
  tags: RBTagIdentifier[];
}

export interface GetTagValuesResponse extends DefaultResponse {
  values: string[];
}