
import { mapGetters } from 'vuex';
import { computed, defineComponent } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';
import { RBTag } from '@/api/dto/report';

export default defineComponent({
  name: 'ReportRecordingsComp',
  props: {
    ...reportProps,
  },
  setup(props) {
    const recordings = computed(() => {
      const recordingsData = props
        .records!.filter((record: any) =>
          record.tags.find(
            (tag: RBTag) => tag.key === 'ap_num_recordings' && tag.value > 0,
          ),
        )
        .map((record: any) => record.data);

      const recordingObjects: any[] = [];
      recordingsData.forEach((data: any) =>
        data.files.forEach((file: any) =>
          recordingObjects.push({
            ...file,
            question: data.question.question,
          }),
        ),
      );

      return recordingObjects;
    });

    return { recordings };
  },
});
