export function hasValue(obj: Record<string, any>, value: string) {
  let matchFound = true;
  const values = Object.values(obj);
  const queryTerms = value.split(' ');
  queryTerms.forEach(term => {
    let hasTerm = false;
    values.forEach(v => {
      if (typeof v === 'string') {
        const lowerCaseString = v.toLowerCase();
        if (lowerCaseString.includes(term)) {
          hasTerm = true;
        }
      }
      if (typeof v === 'number') {
        const number = parseInt(term, 10);
        if (v === number) {
          hasTerm = true;
        }
      }
    });

    if (!hasTerm) {
      matchFound = false;
    }
  });

  return matchFound;
}
