
import ReportPhotos from './ReportPhotos.vue';
import ReportRecordingsComp from './ReportRecordings.vue';
import ReportQuestionsComp from './ReportQuestions.vue';
import ReportSummaryComp from './ReportSummary.vue';
import { defineComponent } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';

export default defineComponent({
  props: {
    ...reportProps,
  },
  name: 'AccidentPlanReport',
  components: {
    ReportPhotos,
    ReportRecordingsComp,
    ReportQuestionsComp,
    ReportSummaryComp,
  },
});
