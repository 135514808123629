
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';
import { UserPrefs } from '@/api/dto/user-prefs';

export default defineComponent({
  name: 'AccidentPlanReportSummaryComp',
  props: {
    ...reportProps,
  },
  setup(props) {
    const metadata: Record<string, any> = {};

    props.report?.tags.forEach(tag => {
      metadata[tag.key] = tag.value;
    });

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    return { metadata, convertDateString, userPrefs };
  },
});
