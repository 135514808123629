
import { ref, onMounted } from 'vue';
import AnswersTable from './AnswersTable.vue';
import { hasValue } from '@/helpers/search';
import { defineComponent } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';
import { getTag } from '@/api/dto/report';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';

export default defineComponent({
  name: 'ReportQuestionsComp',
  props: {
    ...reportProps,
  },
  components: {
    AnswersTable,
  },
  setup() {
    const userPrefs = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });
    const settings = {
      measurementSystem: 'imperial',
      timezone: 0, 
    };

    return {
      settings,
      userPrefs,
      openSections: ref(['']),
      searchQuery: ref(null),
      searchResults: ref(null),
      photosLoaded: ref(0),
      convertDateString
    };
  },
  computed: {
    rawReport() {
      if(!this.records) return [];
      return this.records
        .filter(record => getTag(record.tags, 'type') === 'answer')
        .map(record => record.data);
    },
    tag() {
      if (!this.isPrinting) return 'div';
      return 'el-collapse-item'
    },
    sections() {
      console.log(this.rawReport);
      const sections = {};
      const answers = this.rawReport;
      const photos = [];
      const recordings = [];

      answers.forEach(answer => {
        // add any photos to the array of photos
        answer.files.forEach(file => {
          const fObject = {
            ...file,
            question: answer.question.question,
            question_id: answer.questions_id,
          };
          if (file.mime_type.includes('image')) photos.push(fObject);
        });

        // add any recordings to the array of recordings
        answer.files.forEach(file => {
          const fObject = {
            ...file,
            question: answer.question.question,
            question_id: answer.questions_id,
          };
          if (
            file.mime_type.includes('video') ||
            file.mime_type.includes('audio')
          )
            recordings.push(fObject);
        });

        if (sections[answer.question.section.name] === undefined) {
          sections[answer.question.section.name] = {};
        }

        if (answer.question.terms.length === 0) {
          if (sections[answer.question.section.name].Ungrouped === undefined) {
            sections[answer.question.section.name].Ungrouped = [];
          }

          sections[answer.question.section.name].Ungrouped.push(answer);
          return;
        }

        answer.question.terms.forEach(grouping => {
          if (
            sections[answer.question.section.name][grouping.term] === undefined
          ) {
            sections[answer.question.section.name][grouping.term] = [];
          }

          sections[answer.question.section.name][grouping.term].push(answer);
        });
      });

      const sectionsArr = Object.keys(sections).map(sectionKey => {
        const section = sections[sectionKey];

        const groupingsArr = Object.keys(section).map(groupingKey => {
          const answersArr = section[groupingKey].map(answer => {
            const newAnswer = { ...answer };
            delete newAnswer['segment'];
            delete newAnswer['groupings'];
            return newAnswer;
          });

          return {
            grouping: groupingKey,
            answers: answersArr,
          };
        });

        return {
          section: sectionKey,
          groupings: groupingsArr,
        };
      });

      return sections;
    },
    numPhotos() {
      return this.rawReport.reduce(
        (acc, answer) =>
          acc +
          answer.files.filter(file => file.mime_type.includes('image')).length,
        0,
      );
    },
  },
  methods: {
    updateQuery() {
      this.searchResults = this.rawReport.filter(
        answer =>
          hasValue(answer, this.searchQuery.toLowerCase()) ||
          hasValue(answer.question, this.searchQuery.toLowerCase()),
      );
    },
    onPhotoLoaded() {
      this.photosLoaded += 1;
      this.$emit('load-prog-changed', this.photosLoaded / this.numPhotos);
    },
  },
});
