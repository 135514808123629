<template>
  <div class="report-photos-comp">
    <div v-if="photos.length === 0">
      <h1>No Photos</h1>
      <p>It doesn't look like this report has any photos.</p>
    </div>
    <div v-else class="images-grid">
      <div class="photo-card" v-for="photo in photos" :key="photo.name">
        <el-image
          :src="thumbnail(photo)"
          alt="photo.name"
          :preview-src-list="[photo.url]"
          lazy
        >
          <template #placeholder>
            <div class="image-loading-slot">
              Loading...
            </div>
          </template>
        </el-image>

        <div class="photo-info">
          <el-link
            type="primary"
            class="download-link"
            :href="photo.url"
            download
            >Download</el-link
          >
          <pre>{{ photo.question.question }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rrBaseURL } from '@/api/roadready';
import { defineComponent, computed } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';

export default defineComponent({
  name: 'ReportPhotosComp',
  props: {
    ...reportProps,
  },
  setup(props) {
    const photos = computed(() => {
      const photoData = props.records
        .filter(record =>
          record.tags.find(tag => tag.key === 'ap_num_photos' && tag.value > 0),
        )
        .map(record => record.data);

      const photoObjects = [];
      photoData.forEach(data =>
        data.files.forEach(file =>
          photoObjects.push({
            ...file,
            question: data.question,
          }),
        ),
      );

      return photoObjects;
    });

    const thumbnail = file => {
      return `${rrBaseURL}/var/public/thumbnails/100x100a1/${file.id}_${file.filename}`;
    };

    const srcList = () => {
      return photos.value.map(photo => photo.url);
    };

    return { photos, srcList, thumbnail };
  },
});
</script>

<style lang="scss">
.report-photos-comp {
  .images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
  }

  .photo-card {
    position: relative;
    transition: 0.2s ease;
    display: flex;
    flex-direction: column;

    .photo-info {
      padding: 0 10px;
      background-color: lightgray;
      overflow-y: hidden;

      pre {
        text-align: center;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .download-link {
        padding: 10px;
        margin: 5px auto;
        display: block;
        text-align: center;
        background: white;
      }
    }

    .el-image__preview {
      max-width: 100%;
      border-radius: 3px;
      transition: 0.3s ease;
    }

    .el-image__preview:hover {
      filter: brightness(60%);
    }

    .el-image-viewer__img,
    .el-image__preview {
      background: white;
    }

    .el-image {
      width: 100%;
    }

    .image-loading-slot {
      width: 100%;
      height: 100%;
      min-height: 150px;
      background: lightgray;
      color: gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
