<template>
  <div class="answers-table-comp">
    <el-table
      ref="table"
      stripe
      :data="answers"
      style="width: 100%"
      class="question"
      @row-click="(row, col, event) => rowSelected(row)"
    >
      <el-table-column type="expand">
        <template #default="{ row: answer }">
          <div class="bordered-box question">
            <p>
              <i class="el-icon-time"></i>
              {{ convertDateString(answer.created, userPrefs?.timezone) }}
            </p>
            <h2>
              <pre>{{ answer.question.question }}</pre>
            </h2>
            <p class="answer">
              <span
                v-if="
                  answer.files.length === 1 &&
                    isRecording(answer.files[0].mime_type)
                "
                >RECORDING</span
              >
              <span v-else>{{ answer.answer }}</span>
            </p>

            <el-table
              v-if="answer.files.length > 0"
              :data="answer.files"
              style="width: 100%"
            >
              <el-table-column type="index"> </el-table-column>
              <el-table-column label="Preview" width="350">
                <template #default="scope">
                  <el-image
                    v-if="scope.row.mime_type.includes('image')"
                    :src="thumbnail(scope.row)"
                    :preview-src-list="[scope.row.url]"
                    class="preview-img"
                  ></el-image>
                  <video v-else width="300" height="50" controls>
                    <source :src="scope.row.url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </template>
              </el-table-column>
              <el-table-column label="Download">
                <template #default="scope">
                  <el-link type="primary" :href="scope.row.url"
                    >Download</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Question">
        <template #default="scope">
          <span style="word-break: normal">{{
            scope.row.question.question
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Answer" width="300">
        <template #default="scope">
          <span
            v-if="
              scope.row.files.length === 1 &&
                isRecording(scope.row.files[0].mime_type)
            "
            class="answer"
            >RECORDING</span
          >
          <span
            v-else-if="
              scope.row.files.length === 1 &&
                isImage(scope.row.files[0].mime_type)
            "
            class="answer"
            >IMAGE</span
          >
          <span
            v-else-if="
              scope.row.files.length > 1 &&
                isImage(scope.row.files[0].mime_type)
            "
            class="answer"
            >IMAGES</span
          >
          <span v-else class="answer">{{ scope.row.answer }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Files Available" width="150">
        <template #default="scope">
          <div class="files-available">
            <i
              v-if="scope.row.files.length > 0"
              class="el-icon-document-copy"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { rrBaseURL } from '@/api/roadready';
import { defineComponent, onMounted, ref } from 'vue';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';

export default defineComponent({
  name: 'AnswersTableComp',
  props: ['answers','isPrinting'],
  setup() {
    const userPrefs = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    const thumbnail = file =>
      `${rrBaseURL}/var/public/thumbnails/100x100a1/${file.id}_${file.filename}`;

    return {
      userPrefs,
      thumbnail,
      convertDateString,
    };
  },
  methods: {
    rowSelected(row) {
      this.$refs.table.toggleRowExpansion(row);
    },
    isRecording(mimeType) {
      return mimeType.includes('audio') || mimeType.includes('video');
    },
    isImage(mimeType) {
      return mimeType.includes('image');
    },
  },
});
</script>

<style></style>
